<template>
  <div class="modal-box">
    <div class="modal-banner clearfix">
      <div class="left-search">
        <a-button
          class="search-button"
          shape="circle"
          icon="search"
          @click="searchButton()"
        />
        <input
          v-model="searchModal"
          v-on:keyup.enter="searchButton()"
          class="search-box"
          type="text"
          :placeholder="getString(strings.My_Mind_Search_Template_Type_Or_Template)"
        />
        <span
          v-if="searchModal != ''"
          @click="clearSearchText()"
          class="cleariconfont clear-search-text-icon"
          >&#xe605;</span
        >
      </div>
    </div>
    <div
      v-if="defaultShow && allTemplate != null"
      class="modal-header clearfix"
    >
      <div class="left-button">
        <a-button
          v-for="(item, index) in allTemplate.categories"
          :key="index"
          :class="index == titleIndex ? 'selectButton' : ''"
          @click="classifyButton(index, item.name)"
        >
          {{ item.name }}
        </a-button>
      </div>
      <div v-show="titleIndex != 0" class="child-classify">
        <div
          class="child-classify-each"
          v-for="(item, num) in allTemplate.categories[titleIndex].tags"
          :key="num"
          :class="{ selected: item.selected, selected: smallTitleIndex == num }"
          @click="buttonChild(item, num)"
        >
          {{ item.name }}
        </div>

        <div @click="clearCheck()" class="clearDataButton">{{getString(strings.My_Mind_All)}}</div>
      </div>
    </div>
    <div class="modal-class">
      <div class="modal-class-content clearfix">
        <template v-if="isElectron">
          <div
            class="modal-box-children"
            v-for="(each,index) in publicTemplate"
            :key="index"
            @click="mindTemplate(each.id)"
          >
            <div class="gray-box"></div>
            <div class="modal-icon-box">
              <img
                id="imgColor"
                class="modal-icon"
                v-lazy="getMediaSrc(each.cover)"
                alt=""
              />
            </div>
            <div class="modal-text">
              <div class="ellipsis-text modal-name">{{ each.title }}</div>
              <div class="ellipsis-text subscription-num">
                <div>{{getString(strings.My_Mind_Views)}}</div>
                <div>
                  {{ subscriptionNumber(each.viewCount) }}
                </div>
              </div>
            </div>
            <img
              v-show="each.needMember && !isMember()"
              class="subscription-text"
              src="../../assets/img/user/subscription.png"
              alt=""
            />
          </div>
        </template>
        <template v-else>
          <div
            class="modal-box-children"
            v-for="(each, index) in publicTemplate"
            :key="index"
            
          >
            <a
              class="tempalte-bg-style"
              :href="'/template/?id=' + each.id"
              target="_self"
            >
              <div class="gray-box"></div>
              <div class="modal-icon-box">
                <img
                  id="imgColor"
                  class="modal-icon"
                  v-lazy="getMediaSrc(each.cover)"
                  alt=""
                />
              </div>
              <div class="modal-text">
                <!-- 活动 -->
                <div class="activity-model-text" v-if="containsActivityTag(each.publicTags)">
                  <img src="../../../static/img/activity/activity_title_icon.png" alt="">
                  <div
                    class="ellipsis-text modal-name-activity"
                  >
                  {{ each.title }}
                  </div>
                </div>
                
                <div v-else class="ellipsis-text modal-name">
                  {{ each.title }}
                </div>

                <div class="ellipsis-text subscription-num">
                  <div>{{getString(strings.My_Mind_Views)}}</div>
                  <div>
                    {{ subscriptionNumber(each.viewCount) }}
                  </div>
                </div>
              </div>
              <img
                v-show="each.needMember && !isMember()"
                class="subscription-text"
                src="../../assets/img/user/subscription.png"
                alt=""
              />
            </a>
          </div>
        </template>
      </div>
    </div>
    <div
      v-show="
        showPage &&
        publicTemplate != '' &&
        publicTemplate != null &&
        publicTemplate.length != 0
      "
      class="page-bottom"
    >
      <a-pagination
        class="pageChild"
        @change="onChangePage"
        :current="current"
        :total="total * 10"
        show-less-items
      />
    </div>
    <SpinningAnimation :spinning="spinning" />
  </div>
</template>

<script>
import {
  postTemplateGet,
  postTemplateHome,
  postTemplateQuery,
  postTemplateSearch,
} from "../../common/netWork/template_api";
import SpinningAnimation from "../spinningAnimation/SpinningAnimation";
import strings from '../../common/lang/strings';
import getString from '../../common/lang/language';
import HttpServerConfig from '../../common/HttpServerConfig';
// import apiRequest from "@/network/base";
if ( typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0 ) {
  var {ipcRenderer} = window.require('electron')
  // var ipcRenderer = window.ipcRenderer
}
export default {
  components: {
    SpinningAnimation,
  },
  data() {
    return {
      allTemplate: null,
      publicTemplate: [], //搜索的模板数据或者模板首页数据
      titleIndex: 0, //现在是哪个大类的下标
      smallTitleIndex: null, //现在点击了那个下标签
      defaultShow: true, //默认展示的部分
      current: 2, //当前所在页
      total: 1, //总页数
      searchModal: "", //模板名为空
      showPage: false, //
      spinning: false, //加载动画
      isElectron: false,
      strings:strings,
    };
  },
  created() {
    this.clearCheck();
    this.isElectron = this.$tools.isElectron()
  },
  methods: {
    //语言包
    getString(i){
      return getString(i)
    },
    getMediaSrc(s){
      return HttpServerConfig.getMediaSrc(s)
    },
    containsActivityTag(s){
      if(!s){
        return false;
      }
      for (let index = 0; index < s.length; index++) {
        const element = s[index];
        if(element=='活动'){
          return true
        }
      }
      return false;
    },
    subscriptionNumber(val) {
      //当前的数量
      if (val == null) {
        return;
      }
      if (val < 1000) {
        return val;
      } else if (val < 10000) {
        return parseInt(val / 1000) + "." + parseInt((val % 1000) / 100) + "k";
      } else {
        return (
          parseInt(val / 10000) +
          "." +
          parseInt((val % 10000) / 1000) +
          parseInt(((val % 10000) % 1000) / 100) +
          "w"
        );
      }
    },
    templateHomeData(obj) {
      //获取所有模板信息
      let that = this;
      return new Promise(function (resolve, reject) {
        postTemplateHome(
          obj,
          (res) => {
            resolve(res);
            
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },
    someTemplateHomeData(obj) {
      //获取某个分类信息
      let that = this;
      return new Promise(function (resolve, reject) {
        postTemplateQuery(
          obj,
          (res) => {
            resolve(res);
            
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },
    searchTemplateHomeData(obj) {
      //搜索某个模板信息
      let that = this;
      return new Promise(function (resolve, reject) {
        postTemplateSearch(
          obj,
          (res) => {
            resolve(res);
            
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },

    clearSearchText() {
      this.searchModal = "";
      this.templateHome();
    },

    getSearch(k) {
      // 获取地址栏内容
      var str = window.location.href.split("?")[1];
      // 解码成中文
      str = decodeURIComponent(str);
      var arr = str.split("&");
      var obj = {};
      // 获取键和值
      arr.forEach(function (v, i) {
        var key = v.split("=")[0];
        var value = v.split("=")[1];
        obj[key] = value;
      });
      return obj[k];
    },
    searchButton() {
      //点击搜索按钮
      if (this.searchModal != "") {
        //走搜索
        this.searchTemplate();
      } else {
        //走全部
        this.templateHome();
      }
    },
    searchTemplate() {
      this.spinning = true;
      this.defaultShow = false;
      let obj = {};
      obj.query = this.searchModal;
      this.searchTemplateHomeData(obj)
        .then((data) => {
          this.publicTemplate = data.templates;
        })
        .finally(() => {
          this.titleIndex = 0;
          this.spinning = false;
          this.showPage = false;
        });
    },

    onChangePage(page) {
      //点击分页触发。
      this.current = page;
      this.spinning = true;
      let req = {};
      req.categories = this.reqDataDispose(this.smallTitleIndex);
      req.page = page;
      //执行搜索分类的函数
      this.someTemplateHomeData(req)
        .then((data) => {
          this.publicTemplate = data.templates;
          this.showPage = true;
          //当前页
          this.current = data.currentPage;
          //总页
          this.total = data.totalPage;
        })
        .finally(() => {
          this.spinning = false;
        });
    },

    classifyButton(index, name) {
      //点击大分类按钮
      if (index == this.titleIndex) {
        return;
      }
      if (index == 0) {
        //如果是点击热门。
        this.clearCheck();
        return;
      }
      this.spinning = true;
      this.titleIndex = index;
      this.smallTitleIndex = null; //清空小分类
      let req = {};
      req.categories = this.reqDataDispose(this.smallTitleIndex);
      req.page = 1;
      //执行搜索分类的函数
      this.someTemplateHomeData(req)
        .then((data) => {
          this.publicTemplate = data.templates;
          this.showPage = true;
          //当前页
          this.current = data.currentPage;
          //总页
          this.total = data.totalPage;
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    buttonChild(item, index) {
      //点击小分类按钮
      this.spinning = true;

      //发送请求获得数据
      let req = {};

      //切换选中状态
      let obj = {};
      obj.selected = !item.selected;
      this.smallTitleIndex = index;
      obj.name = item.name;
      // obj = obj;
      this.allTemplate.categories[this.titleIndex].tags.splice(index, 1, obj);
      req.categories = this.reqDataDispose(index);

      req.page = 1;
      //执行搜索分类的函数
      this.someTemplateHomeData(req)
        .then((data) => {
          this.publicTemplate = data.templates;
          this.showPage = true;
          //当前页
          this.current = data.currentPage;
          //总页
          this.total = data.totalPage;
        })
        .finally(() => {
          this.spinning = false;
        });
    },

    reqDataDispose(index) {
      //当前选中的数据
      let all = this.allTemplate.categories;
      let req = [];
      for (let i = 0; i < all.length; i++) {
        for (let j = 0; j < all[i].tags.length; j++) {
          all[i].tags[j].selected = false;
        }
      }

      if (index != undefined) {
        this.allTemplate.categories[this.titleIndex].tags[
          index
        ].selected = true;
      }
      for (let i = 0; i < all.length; i++) {
        let obj = {};
        obj.name = all[i].name;
        obj.id = all[i].id;
        // obj.maxSelection = all[i].maxSelection;
        obj.tags = [];
        for (let j = 0; j < all[i].tags.length; j++) {
          if (all[i].tags[j].selected) {
            let smallObj = {};
            smallObj.name = all[i].tags[j].name;
            obj.tags.push(smallObj);
          }
        }
        if (obj.tags.length !== 0) {
          req.push(obj);
        }
      }
      let whether = true;
      for (let i = 0; i < req.length; i++) {
        //判断是否有当前大类
        if (req[i].id == all[this.titleIndex].id) {
          whether = false;
        }
      }
      if (whether) {
        //如果没有当前大类
        let newObj = {};
        newObj.name = all[this.titleIndex].name;
        newObj.id = all[this.titleIndex].id;
        // newObj.maxSelection = all[this.titleIndex].maxSelection;
        newObj.tags = [];
        req.push(newObj);
      }
      return req;
    },
    clearCheck() {
      //清除选中。
      //分页部分不需要清空，因为模板首页不需要分页数据，下次请求分类的时候会刷新
      //清空大类下标
      this.titleIndex = 0;
      this.templateHome();
    },

    //模板首页
    templateHome() {
      this.spinning = true;
      this.templateHomeData({})
        .then((data) => {
          this.allTemplate = data;
          this.showPage = false;
          this.publicTemplate = this.allTemplate.templates;
        })
        .finally(() => {
          this.spinning = false;
          this.defaultShow = true;
        });
    },
    mindTemplate(id) {
      if (this.$tools.isElectron()) {
        ipcRenderer.send('openCalendarWindow', '/template/?id='+id, { type: 'template' })

      } else {
        // postTemplateGet({ templateId: id }, (res) => {
        //   const routerData = this.$router.resolve({
        //     path: "/template",
        //     query: {
        //       id: res.id,
        //     },
        //   });
        //   // window.open(routerData.href, "_blank");
        // });


      }

    },
  },
  watch: {},
  computed: {},
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: "cleariconfont";
  src: url("../../assets/font-icon/font_ik9g4nleyx/iconfont.eot");
  src: url("../../assets/font-icon/font_ik9g4nleyx/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font_ik9g4nleyx/iconfont.woff2") format("woff2"),
    url("../../assets/font-icon/font_ik9g4nleyx/iconfont.woff") format("woff"),
    url("../../assets/font-icon/font_ik9g4nleyx/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font_ik9g4nleyx/iconfont.svg#iconfont")
      format("svg");
}
.cleariconfont {
  font-family: "cleariconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal-box {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;

  .modal-box {
    position: fixed;
    top: 119px;
  }
  .ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /* 头部开始 */

  .modal-header {
    .ant-btn:hover,
    .ant-btn:focus {
      background: #fd492b;
      color: #fff;
      opacity: 1;
      border: none;
    }

    .left-button {
      margin-right: 40px;
      min-height: 60px;
      padding-left: 20px;
      button {
        height: 28px;
        margin-right: 12px;
        margin-top: 9px;
        font-size: 14px;
        font-family: Noto Sans SC;
        font-weight: 400;
        line-height: 22px;
        color: #333333;
        opacity: 1;
        border-radius: 19px;
        border: none;
      }
      button:hover {
        color: #fff;
      }
      button.selectButton {
        height: 28px;
        margin-top: 3px;
        background: #fd492b;
        color: #fff;
        opacity: 1;
        border: none;
        border-radius: 14px;
      }
    }

    .child-classify {
      min-height: 52px;
      margin-bottom: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: #f0f2f8;
      display: flex;
      flex-wrap: wrap;
      padding-left: 50px;
      position: relative;
      font-size: 13px;
      .clearDataButton {
        position: absolute;
        left: 20px;
        top: 10px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        color: #fd492b;
        cursor: pointer;
      }
      .clearDataButton:active {
        color: #ff002b;
      }

      .child-classify-each {
        cursor: pointer;
        margin-right: 20px;
        margin-left: 20px;
        padding: 4px 10px;
        // margin-bottom: 10px;
        height: 28px;
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0);
        // line-height: 32px;
        color: #666;
      }
      .child-classify-each:hover {
        border: 1px solid #fd492b;
        color: #fd492b;
      }
      .child-classify-each.selected {
        border: 1px solid #fd492b;
        color: #fd492b;
      }
    }
  }

  .left-search {
    width: 570px;
    height: 50px;
    padding-right: 20px;
    margin-top: 20px;
    // display: flex;
    // flex-direction: row-reverse;
    // align-items: center;
    position: relative;
    .clear-search-text-icon {
      display: block;
      position: absolute;
      top: 0;
      // transform: translate(0,-50%);
      font-size: 18px;
      left: 522px;
      height: 40px;
      line-height: 42px;
      color: #bbb;
      cursor: pointer;
      &:hover {
        color: #ff7354;
      }
      &:active {
        color: #d6301a;
      }
    }

    .search-button {
      position: absolute;
      margin-left: 10px;
      margin-top: 0px;
      border: 0px;
      background: none;
    }

    .ant-btn:hover,
    .ant-btn:focus {
      background: #fd492b;
      color: #fff;
      opacity: 1;
      border: none;
    }
    .search-box {
      width: 100%;
      border-radius: 16px;
      border: transparent;
      background: #ececec;
      margin-right: 10px;
      height: 30px;
      padding-left: 45px;
    }
    .search-box:focus {
      outline: none;
    }
  }

  /* 头部结束 */

  .modal-box-children {
    float: left;
    width: 180px;
    height: 222px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(80, 80, 80, 0.16);
    position: relative;
    cursor: pointer;

    .gray-box {
      display: none;
    }
    &:hover .gray-box {
      position: absolute;
      z-index: 2;
      width: 180px;
      height: 222px;
      border-radius: 10px;
      display: block;
      background-color: rgba(0, 0, 0, 0.1);
      &:active {
        position: absolute;
        z-index: 2;
        width: 180px;
        height: 222px;
        border-radius: 10px;
        display: block;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
    .subscription-text {
      position: absolute;
      z-index: 1;
      left: -14px;
      top: -13px;
      color: #fff;
      text-align: center;
    }
    .modal-icon-box {
      width: 176px;
      height: 180px;
      border-radius: 10px;
      margin: 2px 2px;
      overflow: hidden;
      .modal-icon {
        width: 180px;
        height: 160px;
        display: block;
        margin: 0 auto;
      }
    }
    .modal-text {
      height: 60px;
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.3);
      padding: 10px 18px 0 12px;
      border-radius: 0 0 10px 10px;
      .activity-model-text {
        display: flex;
        align-items: center;
        img{
          width: 14px;
          height: 16px;
        }
        .modal-name-activity {
          font-size: 14px;
          font-weight: 400;
          color: #000000;
          display: flex;
          margin-left: 4px;
          color: #ff5f44;
        }
      }
      .modal-name {
        font-size: 14px;
        font-weight: 700;
        color: #383838;
        margin-top: 0px;
        display: flex;
      }
      .subscription-num {
        font-size: 12px;
        display: flex;
        // justify-content: space-between;
        color: #525252;
        margin-top: 3px;
      }
    }
  }

  .page-bottom {
    position: relative;
    margin-top: 30px;
    margin-bottom: 50px;
    .pageChild {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .modal-class {
    min-height: 484px;
  }
}
.modal-class-content {
  .modal-box-children:nth-child(1) {
    background-color: #a6cdef;
    box-shadow: 0px 3px 0px #90b2d0;
  }
  .modal-box-children:nth-child(2) {
    background-color: #ae93e7;
    box-shadow: 0px 3px 0px #8962a7;
  }
  .modal-box-children:nth-child(3) {
    background-color: #e18995;
    box-shadow: 0px 3px 0px #b8707a;
  }
  .modal-box-children:nth-child(4) {
    background-color: #ebc185;
    box-shadow: 0px 3px 0px #c09d6c;
  }
  .modal-box-children:nth-child(5) {
    background-color: #82d0dc;
     box-shadow: 0px 3px 0px #5b99a3;
  }
  .modal-box-children:nth-child(6) {
    background-color: #f0adad;
    box-shadow: 0px 3px 0px #a47777;
  }
  .modal-box-children:nth-child(7) {
    background-color: #abd9e9;
    box-shadow: 0px 3px 0px #88afbc;
  }
  .modal-box-children:nth-child(8) {
    background-color: #f1cecc;
    box-shadow: 0px 3px 0px #bd9f9d;
  }
  .modal-box-children:nth-child(9) {
    background-color: #b6cee4;
    box-shadow: 0px 3px 0px #9db2c4;
  }
  .modal-box-children:nth-child(10) {
    background-color: #b89fee;
    box-shadow: 0px 3px 0px #9179c3;
  }
  .modal-box-children:nth-child(11) {
    background-color: #e18995;
    box-shadow: 0px 3px 0px #a2626b;
  }
  .modal-box-children:nth-child(12) {
    background-color: #f3cc95;
    box-shadow: 0px 3px 0px #c7a678;
  }
  .modal-box-children:nth-child(13) {
    background-color: #82d0dc;
    box-shadow: 0px 3px 0px #66a9b4;
  }
  .modal-box-children:nth-child(14) {
    background-color: #85d999;
    box-shadow: 0px 3px 0px #599968;
  }
  .modal-box-children:nth-child(15) {
    background-color: #d9ceab;
    box-shadow: 0px 3px 0px #aea27b;
  }
  .modal-box-children:nth-child(16) {
    background-color: #f1cecc;
    box-shadow: 0px 3px 0px #c6a5a4;
  }
  .modal-box-children:nth-child(17) {
    background-color: #9db2c4;
    box-shadow: 0px 3px 0px #758796;
  }
  .modal-box-children:nth-child(18) {
    background-color: #a48bdb;
    box-shadow: 0px 3px 0px #8a72bc;
  }
  .modal-box-children:nth-child(19) {
    background-color: #e18995;
  }
  .modal-box-children:nth-child(20) {
    background-color: #ebc185;
    box-shadow: 0px 3px 0px #c09d6c;
  }
  .modal-box-children:nth-child(21) {
    background-color: #82d0dc;
    box-shadow: 0px 3px 0px #66a9b4;
  }
  .modal-box-children:nth-child(22) {
    background-color: #73c4a2;
    box-shadow: 0px 3px 0px #5ea185;
  }
  .modal-box-children:nth-child(23) {
    background-color: #b7b2ec;
    box-shadow: 0px 3px 0px #8a86b5;
  }
  .modal-box-children:nth-child(24) {
    background-color: #f1cecc;
    box-shadow: 0px 3px 0px #c6a5a4;
  }
  .modal-box-children:nth-child(25) {
    background-color: #9db2c4;
    box-shadow: 0px 3px 0px #778795;
  }
  .modal-box-children:nth-child(26) {
    background-color: #b39ee3;
    box-shadow: 0px 3px 0px #9d8bc7;
  }
  .modal-box-children:nth-child(27) {
    background-color: #f09ea9;
    box-shadow: 0px 3px 0px #ca7c87;
  }
  .modal-box-children:nth-child(28) {
    background-color: #ebc185;
    box-shadow: 0px 3px 0px #c8a471;
  }
  .modal-box-children:nth-child(29) {
    background-color: #90deea;
    box-shadow: 0px 3px 0px #66a1ab;
  }
  .modal-box-children:nth-child(30) {
    background-color: #93e0a0;
    box-shadow: 0px 3px 0px #6ca375;
  }
  .modal-box-children:nth-child(31) {
    background-color: #9db2c4;
    box-shadow: 0px 3px 0px rgb(93, 107, 179);
  }
  .modal-box-children:nth-child(32) {
    background-color: #ae93e7;
    box-shadow: 0px 3px 0px #8962a7;
  }
  .modal-box-children:nth-child(33) {
    background-color: #e18995;
    box-shadow: 0px 3px 0px #814970;
  }
  .modal-box-children:nth-child(34) {
    background-color: #ebc185;
    box-shadow: 0px 3px 0px #bd9b6b;
  }
  .modal-box-children:nth-child(35) {
    background-color: #82d0dc;
     box-shadow: 0px 3px 0px #3a6167;
  }
  .modal-box-children:nth-child(36) {
    background-color: #f0adad;
    box-shadow: 0px 3px 0px #a47777;
  }
  .modal-box-children:nth-child(37) {
    background-color: #abd9e9;
    box-shadow: 0px 3px 0px #88afbc;
  }
  .modal-box-children:nth-child(38) {
    background-color: #f1cecc;
    box-shadow: 0px 3px 0px #bd9f9d;
  }
  .modal-box-children:nth-child(39) {
    background-color: #b6cee4;
    box-shadow: 0px 3px 0px #9db2c4;
  }
  .modal-box-children:nth-child(40) {
    background-color: #b89fee;
    box-shadow: 0px 3px 0px #9179c3;
  }
  .modal-box-children:nth-child(41) {
    background-color: #e18995;
    box-shadow: 0px 3px 0px #a2626b;
  }
  .modal-box-children:nth-child(42) {
    background-color: #f3cc95;
    box-shadow: 0px 3px 0px #c7a678;
  }
  .modal-box-children:nth-child(43) {
    background-color: #82d0dc;
    box-shadow: 0px 3px 0px #66a9b4;
  }
  .modal-box-children:nth-child(44) {
    background-color: #85d999;
    box-shadow: 0px 3px 0px #599968;
  }
  .modal-box-children:nth-child(45) {
    background-color: #d9ceab;
    box-shadow: 0px 3px 0px #aea27b;
  }
}
</style>